import React, { useState } from "react";
import "./Footer.css";
import axios from "axios";
import img1 from "../../../image/maxgen_Logo_PNG.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [subemail, setSubEmail] = useState("");
  const [subresult, setSubResult] = useState("");
  const [subBtnLoad, setSubBtnLoad] = useState(false);
  const [hidestp, showstp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 400) {
      showstp(true);
    } else if (scrolled <= 400) {
      showstp(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  const checkSubEmail = (e) => {
    setSubEmail(e.target.value);
    const m = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value.match(m) == null) {
      document.getElementById("emailerr1").style.display = "block";
    } else {
      document.getElementById("emailerr1").style.display = "none";
    }
  };
  const handleSubscribe = (e) => {
    e.preventDefault();
    const m = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (subemail === "") {
      document.getElementById("emailerr1").style.display = "block";
    } else if (subemail.match(m) === null) {
      document.getElementById("emailerr1").style.display = "block";
    } else {
      const url =
        "https://www.maxgentechnologies.com/maxgenApi/subcription.php/";
      const formData1 = new FormData();
      formData1.append("subemail", subemail);
      axios
        .post(url, formData1, {
          before: setSubBtnLoad(true),
        })
        .then((res) => {
          setSubBtnLoad(false);
          console.log(res);
          setSubEmail("");
          const msg = res.data;
          setSubResult(msg.message);
        });
    }
  };
  return (
    <div className="container2">
      <div className="content-container">
        <div className="first-part">
          <NavLink exact to="/home">
            <img src={img1} alt="Maxgen Technologies Pvt. Ltd." />
          </NavLink>
        </div>
        <div className="second-part">
          <h3>Resources</h3>
          <ul>
            <li>
              <NavLink exact to="/services">
                Services
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/internship">
                Internship
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/career">
                Career
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/portfolio">
                Portfolio
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="third-part">
          <h3>Company</h3>
          <ul>
            <li>
              <NavLink exact to="/about">
                About
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/contact">
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/blogs">
                Blog
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="fourth-part">
          <h3>Legal</h3>
          <ul>
            <li>
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
            </li>
            {/* <li><NavLink>Terms Of Service</NavLink></li> */}
          </ul>
        </div>
        <div className="fifth-part">
          <div className="subscribe">
            <form onSubmit={handleSubscribe} autoComplete="off">
              <input
                type="text"
                name="subemail"
                value={subemail}
                onChange={checkSubEmail}
                placeholder="Subscribe for email updates"
              />

              <button
                type="submit"
                className="footer-btn"
                disabled={subBtnLoad ? "disabled" : ""}
              >
                <div
                  style={
                    subBtnLoad ? { display: "block" } : { display: "none" }
                  }
                  className="footer-ring"
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <i
                  style={
                    subBtnLoad ? { display: "none" } : { display: "block" }
                  }
                  className="fa-solid fa-greater-than"
                ></i>
              </button>
            </form>
            <span id="emailerr1">Please enter valid email</span>
            <p className="msg"> {subresult !== "" ? subresult : ""}</p>
          </div>

          <div className="socials">
            <a
              href="https://www.facebook.com/maxgentechnologies"
              target="_blank"
            >
              <i class="fa-brands fa-square-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/maxgen_technologies/"
              target="_blank"
            >
              <i class="fa-brands fa-square-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/maxgen-technologies"
              target="_blank"
            >
              <i class="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          All copyrights reserved &#169;&nbsp;2013 - Maxgen Technologies Pvt.
          Ltd.
        </p>
      </div>
      {/* <div className="bottom-line">
        <a href="https://api.whatsapp.com/send?phone=9579972245" className="float" target="_blank">
          <i class="fa-brands fa-whatsapp my-float"></i>
        </a>
        <div className={!hidestp ? "stp hiddenmc" : "stp showmc"} id="stpbtn">
          <button className="stpbtn" onClick={scrollToTop}><i class="fa-solid fa-arrow-up"></i></button>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
