import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Header from "./components/common/Header/Header";
import React, { useState, useEffect } from "react";
import Loader from "./components/common/Loader/Loader";
import Footer from "./components/common/Footer/Footer";
// const LazyHome = React.lazy(() => import("./components/Home/Home"));
import Home from './components/Home/Home'

const LazyServices = React.lazy(() => import("./components/Services/Services"));
const LazyInternship = React.lazy(() =>
  import("./components/Internship/Internship")
);
const LazyPortfolio = React.lazy(() =>
  import("./components/Portfolio/Portfolio")
);
const LazyQuote = React.lazy(() => import("./components/Quote/Quote"));
const LazyAbout = React.lazy(() => import("./components/About/About"));
const LazyContact = React.lazy(() => import("./components/Contact/Contact"));
const LazyCareer = React.lazy(() => import("./components/Career/Career"));
const LazyBlogs = React.lazy(() => import("./components/Blogs/Blogs"));


const LazyPrivacy = React.lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);

const LazySingleBlog = React.lazy(() =>
  import("./components/Blogs/Blog")
);



const LazyWebDev = React.lazy(() =>
  import("./components/Services/WebDev")
);
const LazyMobileApp = React.lazy(() =>
  import("./components/Services/MobileApp")
);
const LazyLogoDesign = React.lazy(() =>
  import("./components/Services/LogoDesign")
);
const LazySupportMain = React.lazy(() =>
  import("./components/Services/SupportMain")
);
const LazyEcommDev = React.lazy(() =>
  import("./components/Services/EcomDev")
);

const LazySEODig = React.lazy(() =>
  import("./components/Services/SEODig")
);



const LazyAndroidInternship = React.lazy(() =>
  import("./components/Internship/AndroidInternship")
);
const LazyAwsInternship = React.lazy(() =>
  import("./components/Internship/AwsInternship")
);
const LazyDataScienceInternship = React.lazy(() =>
  import("./components/Internship/DataScienceIntenship")
);
const LazyDotNetInternship = React.lazy(() =>
  import("./components/Internship/FlutterIntenship")
);
const LazyJavaInternship = React.lazy(() =>
  import("./components/Internship/JavaIntenship")
);
const LazyPhpInternship = React.lazy(() =>
  import("./components/Internship/PhpIntenship")
);
const LazyPythonInternship = React.lazy(() =>
  import("./components/Internship/PythonIntenship")
);
const LazyDjangoInternship = React.lazy(() =>
  import("./components/Internship/djangointernship")
);
const LazySapInternship = React.lazy(() =>
  import("./components/Internship/DataAnalyticsIntenship")
);
const LazyReactInternship = React.lazy(() =>
  import("./components/Internship/ReactInternship")
);
const LazySoftwareTestingInternship = React.lazy(() =>
  import("./components/Internship/SoftwareTestingIntenship")
);
const LazyAndroidDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/AndroidDevloper")
);
const LazyPhpDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/PhpDeveloper")
);
const LazyJavaDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/JavaDeveloper")
);
const LazyReactDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/ReactDeveloper")
);
const LazyPythonDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/PythonDeveloper")
);
const LazyAwsDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/AwsDeveloper")
);
const LazyDataScienceDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/DataScienceDeveloper")
);
const LazySoftwreTestingCareer = React.lazy(() =>
  import("./components/Career/CareerDetails/SoftwaretestingDeveloper")
);
const LazyFlutterDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/FlutterDeveloper")
);
const LazyDataAnalyticsDeveloper = React.lazy(() =>
  import("./components/Career/CareerDetails/DataAnalytist")
);


function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
  }, [])


  return (
    <>

      {loading === false ? (
        <div>
          <Header />

          <div className="container">
            <Routes>
              <Route
                exact="true"
                path="/"
                element={
                  // <React.Suspense fallback={<Loader />}>
                  //   <LazyHome />
                  // </React.Suspense>
                  <Home />
                }
              />
              <Route
                path="/home"
                element={
                  // <React.Suspense fallback={<Loader />}>
                  //   <LazyHome />
                  // </React.Suspense>
                  <Home />
                }
              />
              <Route
                path="/services"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyServices />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/portfolio"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPortfolio />
                  </React.Suspense>
                }
              />
              <Route
                path="/career"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyCareer />
                  </React.Suspense>
                }
              />
              <Route
                path="/about"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyAbout />
                  </React.Suspense>
                }
              />
              <Route
                path="/contact"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyContact />
                  </React.Suspense>
                }
              />
              <Route
                path="/privacypolicy"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPrivacy />
                  </React.Suspense>
                }
              />
              <Route
                path="/blogs"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyBlogs />
                  </React.Suspense>
                }
              />
              <Route
                path="/blog/:name"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySingleBlog />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/androidinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyAndroidInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/awsinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyAwsInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/datascienceinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyDataScienceInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/flutterinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyDotNetInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/javainternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyJavaInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/phpinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPhpInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/djangointernship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyDjangoInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/pythoninternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPythonInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/dataanalyticsinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySapInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/softwaretestinginternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySoftwareTestingInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/internship/reactinternship"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyReactInternship />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/web-design-and-developement"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyWebDev />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/mobile-app-developement"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyMobileApp />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/logo-design-and-graphic-design"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyLogoDesign />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/support-and-maintenance"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySupportMain />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/e-commerce-developement"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyEcommDev />
                  </React.Suspense>
                }
              />
              <Route
                path="/services/SEO-and-digital-marketing-services"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySEODig />
                  </React.Suspense>
                }
              />


              <Route
                path="/career/androiddeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyAndroidDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/phpdeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPhpDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/javadeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyJavaDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/reactjsdeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyReactDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/pythondeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyPythonDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/awsdeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyAwsDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/datascientist/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyDataScienceDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/softwaretester/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazySoftwreTestingCareer />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/flutterdeveloper/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyFlutterDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/career/dataanalytist/:id"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyDataAnalyticsDeveloper />
                  </React.Suspense>
                }
              />
              <Route
                path="/get-quote/:type"
                element={
                  <React.Suspense fallback={<Loader />}>
                    <LazyQuote />
                  </React.Suspense>
                }
              />
              <Route
                path="/*"
                element={<Navigate replace to="/home" />

                }
              />
            </Routes>

          </div>
          <Footer />



        </div>
      ) : (<Loader />)}
    </>
  );
}

export default App;
