import React from 'react'
import Navbar from './Navbar/Navbar'
import './style.css'

const Header = () => {

    return (
        <div className='mainheader'>
            <div className='header'>
                <Navbar />
            </div>
            <div className='floating-quote'>
                <a href='https://maxgentechnologies.com/web-and-mobile-app-development-services.html' target='_blank'><i class="fa-solid fa-message"></i>&nbsp;&nbsp;&nbsp;Get Quote Now</a>
            </div>
            
        </div >
    )
}

export default Header