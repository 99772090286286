import React, { useState, useEffect } from "react";
import axios from "axios";
import confirmgif from "../../image/confirm.gif";
import img1 from "../../image/fomr-img.webp";
import img2 from "../../image/india.png";
import "./ModalInt.css";

const ModalFormInt = () => {
  const [count, setCount] = useState(60);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [internship, setInternship] = useState("");
  const [result, setResult] = useState("");
  const [id, setId] = useState("");
  const [otp, setOtp] = useState("");
  const [showotp, setShowOtp] = useState(false);
  const [showform, setShowForm] = useState(true);
  const [showform1, setShowForm1] = useState(false);
  const [showform2, setShowForm2] = useState(false);
  const [btnload, setBtnLoad] = useState(false);

  useEffect(() => {
    if (showform1 === true) {
      if (count !== 0) {
        setTimeout(() => {
          setCount(count - 1);
        }, 1000);
      } else {
        setShowOtp(true);
      }
    }
    if (showform2 === true) {
      setTimeout(() => {
        setShowForm2(false);
        setShowForm(true);
      }, 6000);
    }
  });

  function checkEmail(e) {
    setEmail(e.target.value);
    const m = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value.match(m) == null) {
      document.getElementById("emailerr").style.display = "block";
    } else {
      document.getElementById("emailerr").style.display = "none";
    }
  }
  function checkAddress(e) {
    setAddress(e.target.value);
    if (e.target.value === "") {
      document.getElementById("adderr").style.display = "block";
    } else {
      document.getElementById("adderr").style.display = "none";
    }
  }

  function checkMobile(e) {
    setMobile(e.target.value);
    if (e.target.value.match("^[9876]{1}[0-9]{9}$") == null) {
      document.getElementById("phnerr").style.display = "block";
    } else {
      document.getElementById("phnerr").style.display = "none";
    }
  }

  function checkName(e) {
    setName(e.target.value);
    if (e.target.value.match("^[a-zA-Z ]*$") == null) {
      document.getElementById("nameerr").style.display = "block";
    } else {
      document.getElementById("nameerr").style.display = "none";
    }
  }
  function checkCity(e) {
    setCity(e.target.value);
    if (e.target.value === "") {
      document.getElementById("cityerr").style.display = "block";
    } else {
      document.getElementById("cityerr").style.display = "none";
    }
  }
  function checkInternship(e) {
    setInternship(e.target.value);
    if (e.target.value === "") {
      document.getElementById("interr").style.display = "block";
    } else {
      document.getElementById("interr").style.display = "none";
    }
  }
  function checkOtp(e) {
    setOtp(e.target.value);
    if (e.target.value.match("^[0-9]{6}$") === null) {
      document.getElementById("otperr").style.display = "block";
    } else {
      document.getElementById("otperr").style.display = "none";
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const n = /^[a-zA-Z ]*$/;
    const m = /^[9876]{1}[0-9]{9}$/;
    const em = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      name === "" &&
      email === "" &&
      mobile === "" &&
      address === "" &&
      city === "" &&
      internship === ""
    ) {
      document.getElementById("interr").style.display = "block";
      document.getElementById("nameerr").style.display = "block";
      document.getElementById("phnerr").style.display = "block";
      document.getElementById("emailerr").style.display = "block";
      document.getElementById("adderr").style.display = "block";
      document.getElementById("cityerr").style.display = "block";
    } else if (name === "") {
      document.getElementById("nameerr").style.display = "block";
      document.getElementById("interr").style.display = "none";
      document.getElementById("phnerr").style.display = "none";
      document.getElementById("emailerr").style.display = "none";
      document.getElementById("adderr").style.display = "none";
      document.getElementById("cityerr").style.display = "none";
    } else if (name.match(n) === null) {
      document.getElementById("nameerr").style.display = "block";
    } else if (email === "") {
      document.getElementById("nameerr").style.display = "none";
      document.getElementById("interr").style.display = "none";
      document.getElementById("phnerr").style.display = "none";
      document.getElementById("emailerr").style.display = "block";
      document.getElementById("adderr").style.display = "none";
      document.getElementById("cityerr").style.display = "none";
    } else if (email.match(em) === null) {
      document.getElementById("emailerr").style.display = "block";
    } else if (mobile === "") {
      document.getElementById("interr").style.display = "none";
      document.getElementById("nameerr").style.display = "none";
      document.getElementById("phnerr").style.display = "block";
      document.getElementById("emailerr").style.display = "none";
      document.getElementById("adderr").style.display = "none";
      document.getElementById("cityerr").style.display = "none";
    } else if (mobile.match(m) === null) {
      document.getElementById("phnerr").style.display = "block";
    } else if (city === "") {
      document.getElementById("interr").style.display = "none";
      document.getElementById("nameerr").style.display = "none";
      document.getElementById("phnerr").style.display = "none";
      document.getElementById("emailerr").style.display = "none";
      document.getElementById("adderr").style.display = "none";
      document.getElementById("cityerr").style.display = "block";
    } else if (address === "") {
      document.getElementById("interr").style.display = "none";
      document.getElementById("nameerr").style.display = "none";
      document.getElementById("phnerr").style.display = "none";
      document.getElementById("emailerr").style.display = "none";
      document.getElementById("adderr").style.display = "block";
      document.getElementById("cityerr").style.display = "none";
    } else if (internship === "") {
      document.getElementById("interr").style.display = "block";
      document.getElementById("nameerr").style.display = "none";
      document.getElementById("phnerr").style.display = "none";
      document.getElementById("emailerr").style.display = "none";
      document.getElementById("adderr").style.display = "none";
      document.getElementById("cityerr").style.display = "none";
    } else {
      const url =
        "https://www.maxgentechnologies.com/maxgenApi/addinternship.php/";
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("internship", internship);

      axios
        .post(url, formData, {
          before: setBtnLoad(true),
        })
        .then((response) => {
          setBtnLoad(false);
          const msg = response.data;
          if (msg.status === 1) {
            setId(msg.id);
            setShowForm2(true);
            setShowForm1(false);
            setResult(msg.message);
            setName("");
            setEmail("");
            setMobile("");
            setAddress("");
            setCity("");
            setInternship("");
            setOtp("");
          } else {
            setResult(msg.message);
          }
        });
    }
  };
  //   const handleOtpSubmit = (e) => {
  //     e.preventDefault();
  //     const o = /^[0-9]{6}$/;
  //     if (otp === "") {
  //       document.getElementById("otperr").style.display = "block";
  //     } else if (otp.match(o) === null) {
  //       document.getElementById("otperr").style.display = "block";
  //     } else {
  //       const url =
  //         "https://www.maxgentechnologies.com/maxgenApi/checkintotp.php/";
  //       const formData1 = new FormData();
  //       formData1.append("otp", otp);
  //       formData1.append("regemail", email);
  //       axios
  //         .post(url, formData1, {
  //           before: setBtnLoad(true),
  //         })
  //         .then((res) => {
  //           const msg = res.data;
  //           setBtnLoad(false);
  //           if (msg.status === 1) {
  //             setShowForm2(true);
  //             setShowForm1(false);
  //             setResult(msg.message);
  //             setName("");
  //             setEmail("");
  //             setMobile("");
  //             setAddress("");
  //             setCity("");
  //             setInternship("");
  //             setOtp("");
  //           } else {
  //             setResult(msg.message);
  //           }
  //         });
  //     }
  //   };
  //   const resendOtp = (e) => {
  //     e.preventDefault();
  //     const url =
  //       "https://www.maxgentechnologies.com/maxgenApi/resendintotp.php/";
  //     const formData1 = new FormData();
  //     formData1.append("id", id);
  //     formData1.append("regemail", email);
  //     axios.post(url, formData1).then((res) => {
  //       setShowOtp(false);
  //       setCount(60);
  //       const msg = res.data;
  //       setResult(msg.message);
  //     });
  //   };
  return (
    <div className="form">
      <div className="form-image-holder">
        <img src={img1} alt="Internship Application" />
      </div>
      <div>
        <p className="form-heading-1">Internship Application</p>
      </div>
      <p className="form-content-2">
        Please fill the form to apply for the internship. Make sure the
        information you provided is correct.
      </p>
      <form
        onSubmit={handleSubmit}
        className={showform === false ? "hidden-internship" : "show-internship"}
        autoComplete="off"
      >
        <div className="form-row">
          <label htmlFor="name">Full name</label>
          <input
            id="name"
            type="text"
            name="name"
            value={name}
            onChange={checkName}
            placeholder="Your name"
          />
          <span id="nameerr">Please enter valid name</span>
        </div>
        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={checkEmail}
            placeholder="Your email"
          />
          <span id="emailerr">Please enter valid email</span>
        </div>
        <div className="form-row">
          <label htmlFor="contact">Contact no.</label>
          <div className="contact-field">
            <div className="contact-field-img">
              <img src={img2} alt="" />
            </div>
            <div className="contact-placeholder">+91</div>
            <input
              type="text"
              name="mobile"
              id="contact"
              value={mobile}
              maxLength="10"
              onChange={checkMobile}
            />
          </div>
          <span id="phnerr">Please enter valid phone number</span>
        </div>
        <div className="form-row">
          <label htmlFor="addd">Address</label>
          <textarea
            name="address"
            id="addd"
            value={address}
            onChange={checkAddress}
            placeholder="Your address"
          ></textarea>
          <span id="adderr">Enter valid address</span>
        </div>
        <div className="flex">
          <div className="form-row">
            <label htmlFor="city">City for internship</label>
            <select
              id="city"
              name="city"
              className="city-field"
              value={city}
              onChange={checkCity}
            >
              <option value="">City</option>
              <option value="Ahmedabad">Ahmedabad</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Pune">Pune</option>
            </select>
            <span id="cityerr">Please select a city</span>
          </div>
          <div className="form-row">
            <label htmlFor="tech">Technology:</label>
            <select
              id="tech"
              name="internship"
              value={internship}
              onChange={checkInternship}
            >
              <option value="">Please select technology</option>
              <option value="Android Internship">Android Internship</option>
              <option value="AWS Internship">AWS Internship</option>
              <option value="DS & ML Internship">DS & ML Internship</option>
              <option value="Data Analytics Internship">
                Data Analytics Internship
              </option>
              <option value="Software Testing Internship">
                Software Testing Internship
              </option>
              <option value="JAVA Internship">JAVA Internship</option>
              <option value="React Js Internship">React Js Internship</option>
              <option value="Flutter Internship">Flutter Internship</option>
              <option value="PHP Web Development Internship">
                PHP Web Development Internship
              </option>
              <option value="Python DJango Internship">
                Python DJango Internship
              </option>
            </select>
            <span id="interr">Please select a Internship course</span>
          </div>
        </div>
        <div className="form-row">
          <button
            type="submit"
            disabled={btnload ? "disabled" : ""}
            className="internship-model-btn"
          >
            <div
              style={btnload ? { display: "block" } : { display: "none" }}
              className="internship-ring"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <o style={btnload ? { display: "none" } : { display: "block" }}>
              <x>Submit</x>
            </o>
          </button>
        </div>
      </form>

      {/* <form
        onSubmit={handleOtpSubmit}
        className={
          showform1 === false ? "hidden-internship" : "show-internship"
        }
        autoComplete="off"
      >
        <p className="msg"> {result !== "" ? result : ""}</p>
        <div className="form-row">
          <label htmlFor="otp">Enter Otp</label>
          <input type="hidden" value={email} name="regemail" />
          <input type="hidden" value={id} name="id" />
          <input
            type="text"
            id="otp"
            name="otp"
            value={otp}
            maxLength="6"
            onChange={checkOtp}
            placeholder="Enter OTP"
          />
          <span id="otperr">Please enter valid OTP</span>
          <div className="otpbtn">
            {showotp === false ? (
              <p className="resendotp">Resend otp in {count}</p>
            ) : (
              <button className="resendotp" onClick={resendOtp}>
                Resend Otp
              </button>
            )}
          </div>
        </div>
        <div className="form-row">
          <button
            type="submit"
            disabled={btnload ? "disabled" : ""}
            className="internship-model-btn"
          >
            <div
              style={btnload ? { display: "block" } : { display: "none" }}
              className="internship-ring"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <o style={btnload ? { display: "none" } : { display: "block" }}>
              <x>Submit</x>
            </o>
          </button>
        </div>
      </form> */}
      <div
        className={
          showform2 === false ? "confirmation hidden" : "confirmation show"
        }
      >
        <div className="confirm-img">
          <img src={confirmgif} alt="confirm" />
          <p className="confirm-msg"> {result !== "" ? result : ""}</p>
        </div>
      </div>
    </div>
  );
};

export default ModalFormInt;
