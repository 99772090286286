import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./style.css";
import img1 from "../../../../image/Maxgen_technologies.png";
import { BiCaretDown } from "react-icons/bi";

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact="true" to="/home" className="nav-logo">
            <img src={img1} alt="Maxgen Technologies Pvt. Ltd." />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/home"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/services"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/internship"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Internship
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/portfolio"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/career"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/blogs"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blogs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact="true"
                to="/contact"
                activeClassName="active"
                className="nav-button"
                onClick={handleClick}
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div
            onClick={handleClick}
            className={click ? "burger toggle" : "burger"}
          >
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
            <div class="line4"></div>
            <div class="line5"></div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
