import React from 'react'
import ReactDOM from 'react-dom'
import './style.css'

const Modal = ({ visible, children, isVisible, name }) => {
  if (!visible) {
    return (
      null
    )
  }
  else {
    return ReactDOM.createPortal(
      <>
        <div className={visible ? 'overlay animation' : 'overlay'}></div>
        <div className={visible ? 'modal animation' : 'modal'}>
          <div className='modal-header'>
            {/* <div className='form-image-holder'>
              <img src={img1} alt="" />
            </div> */}
            <div className='close-btn' onClick={isVisible}><i class="fa-solid fa-xmark"></i></div>
          </div>
          <div className='modal-body'>
            {children}
          </div>
        </div>
      </>,
      document.getElementById('portal')
    )
  }
}

export default Modal
