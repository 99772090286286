import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import './BlogSlider.css'

import dummyImg from '../../../image/dummy-blog.jpg'

import dummy from '../../../image/dummy.webp'


// import required modules
import { Autoplay, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

export default function App() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [filtered, setFiltered] = useState([])

    useEffect(() => {
        axios.get('https://maxgentechnologies.com/maxgenApi/getblogs.php').then((response) => {
            if (response.data.status === 0) {
                setData([])
                setFiltered([])
            }
            else {
                setData(response.data.data)
                setFiltered(response.data.data)
                setLoading(true)
            }
        }
        )
    }, [])

    return (
        <>
            <Swiper
                slidesPerView={1}
                breakpoints={{
                    580: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                {
                    data.slice(0, 6).map((item) => {
                        const slug = item.title.replaceAll(" ", "-")
                        return (
                            <>
                                <SwiperSlide className="slide">
                                    <div className="blog-main-card">
                                        <img className="" src={item.image} alt={item.title} />
                                        <div className="blog-con">
                                            <h4>{item.title}</h4>
                                            <p className="blog-con-desc" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            <Link  target='_blank' to={`/blog/${slug}`}>Read more &nbsp;&rarr;</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </>
                        )
                    })
                }

            </Swiper>
        </>
    );
}
