import React, { useEffect, useState } from "react";
import Aos from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "aos/dist/aos.css";
import "./style.css";
import intimage from "../../image/IT-internship-programs.webp";
import who_we_are from "../../image/who-we-are.webp";
import expertise from "../../image/expertise.webp";
import asset1 from "../../image/identify.webp";
import asset2 from "../../image/impliment.webp";
import asset3 from "../../image/manage.webp";
import img1 from "../../image/mobile-application-developement.webp";
import img2 from "../../image/Web-design-development.webp";
import img3 from "../../image/Logo-and-graphic-design.webp";
import img4 from "../../image/ecommerce-website.webp";
import img5 from "../../image/Websites-maintainence-services.webp";
import img6 from "../../image/seo-and-digital-marketing.webp";
import { Helmet } from "react-helmet";

import { NavLink } from "react-router-dom";
import ModalInt from "../common/Modal/ModalInt";
import ModalFormInt from "../Internship/ModalFormInt";
import "../Internship/style.css";

import BlogSlider from "./BlogSlider/BlogSlider";

const Home = () => {
  useEffect(() => {
    document.title =
      "Maxgen Technologies Pvt. Ltd. | Best Web Designing & Web Development Company in Ahmedabad Pune Navi Mumbai India";
    Aos.init({ duration: 1000 });
    window.scrollTo(0, 0);
  }, []);

  const [modal, setModal] = useState(false);

  return (
    <>
      <div className="home-container">
        <Helmet>
          <title>
            SEO, Web & Mobile App Development Services | IT Internship
          </title>
          <meta
            name="description"
            content="Maxgen Technologies Is The Best Web & Mobile App Development, SEO, Digital Marketing Agency & IT Internship Company In Ahmedabad | Pune | Navi Mumbai | USA."
          />
          <meta
            name="keywords"
            content="search engine optimization, web development services, web design services ,seo services, mobile app development services, flutter app development,ios app development, android app development services ,digital marketing agency ,internship ,internship companies ,data analyst internship, python internship,data science Internship ,react js internship, digital marketing , IT company ,IT companies  near me  , Top 10 IT companies ."
          />
        </Helmet>
        <div className="banner-slider">
          <div className="banner-left">
            {/* <img src={bannerimage} alt="bannerimage" /> */}
            <Swiper
              effect={"fade"}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              modules={[Autoplay, EffectFade]}
              className="mySwiper1"
            >
              <SwiperSlide>
                <img
                  src={img1}
                  alt="create your mobile app with maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img2}
                  alt="Create your Business Website with us at Maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img3}
                  alt="Create your Business Logo & Graphics with us at Maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img4}
                  alt="Create your E-Commerce Website with us at Maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img5}
                  alt="Maintain your Business Website/App with us at Maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img6}
                  alt="Get your SEO services with us at Maxgen"
                  loading="lazy"
                />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="banner-right">
            <Swiper
              autoplay={{
                delay: 3000,

                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              modules={[Autoplay]}
              className="mySwiper1"
            >
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Create your Mobile App with Us at <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    Experienced developers provide special services for
                    developing Android, iOS, and hybrid applications.
                  </p>
                  <NavLink
                    to="/get-quote/android-application"
                    className="slider-btn"
                  >
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Create your Business Website with Us at <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    We brand your website according to your unique requirements
                    and provide our experience in the process. The outcome is a
                    customised web portfolio of your services ready to wow
                    visitors.
                  </p>
                  <NavLink
                    to="/get-quote/business-website"
                    className="slider-btn"
                  >
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Create your Business Logo & Graphics with Us at{" "}
                    <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    When designing a logo and other visuals for your company,
                    our designers know how to use visual components, colours,
                    and graphics efficiently.
                  </p>
                  <NavLink
                    to="/get-quote/graphic-design"
                    className="slider-btn"
                  >
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Create your E-Commerce Website with Us at{" "}
                    <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    A successful and highly effective E-Commerce website needs a
                    few essential components. Here, we'll decipher it for you.
                  </p>
                  <NavLink
                    to="/get-quote/ecommerce-website"
                    className="slider-btn"
                  >
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Maintain your Business Website/App with Us at{" "}
                    <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    A better digital experience for your customers and staff is
                    made possible by always on managed services and support.
                  </p>
                  <NavLink to="/get-quote/web-hosting" className="slider-btn">
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="right-content">
                  <h1>
                    Get your SEO Services with Us at <span>Maxgen</span>
                  </h1>
                  <hr />
                  <p>
                    SEO is all about creating valuable content that will attract
                    links from other websites
                  </p>
                  <NavLink to="/get-quote/web-hosting" className="slider-btn">
                    <x>Get Quote Now</x>
                  </NavLink>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div>
          <div className="sec3" data-aos="fade-down">
            <h2>Let Us Help with Your Business IT Needs</h2>
            <p>
              We are one of its kind of product development company that offers
              competitive software solutions to unleash your business potential.
              we are helping brands to navigate through their digital
              transformation journey.
            </p>
            <div className="sec3-main">
              <hr data-aos="fade-in" />
              <div className="imgs">
                <span className="img-main" data-aos="flip-right">
                  <img src={asset1} alt="Identify" />
                  <p className="img-sub">Identify</p>
                </span>
                <span className="img-main" data-aos="flip-right">
                  <img src={asset2} alt="Implement" />
                  <p className="img-sub">Implement</p>
                </span>
                <span className="img-main" data-aos="flip-right">
                  <img src={asset3} alt="Manage" />
                  <p className="img-sub">Manage</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="sec2">
          <div className="sec2-right" data-aos="fade-right">
            <img
              src={who_we_are}
              alt="We are a full service digital consultant with the skills and ability to serve the requirements of even the biggest and most complicated businesses worldwide. To ensure that we focus on their needs and customers and provide real value to the business, we can provide clients with the greatest possible experiences while working with our team, thanks to our available services portfolio. Endless ideas, end-to-end services."
            />
          </div>
          <div className="sec2-left" data-aos="fade-left">
            <h2 className="heading">Who We Are ?</h2>

            <p>
              We are a full service{" "}
              <a
                className="link"
                href="https://maxgentechnologies.com/services"
                target="_blank"
              >
                digital consultant
              </a>{" "}
              with the skills and ability to serve the requirements of even the
              biggest and most complicated businesses worldwide. To ensure that
              we focus on their needs and customers and provide real value to
              the business, we can provide clients with the greatest possible
              experiences while working with our team, thanks to our available
              services{" "}
              <a
                className="link"
                href="https://maxgentechnologies.com/portfolio"
                target="_blank"
              >
                portfolio
              </a>
              . Endless ideas, end-to-end services.
            </p>
          </div>
        </div>
        <div className="sec4">
          <div className="sec4-left" data-aos="fade-right">
            <h4 className="heading">Our Expertise & Support</h4>

            <p>
              We have team of expertise who helps developers in multiple way.
            </p>

            <ul>
              <li>
                <a
                  href="https://maxgentechnologies.com/services "
                  target="_blank"
                >
                  <span className="hover">
                    Web Design and Development &nbsp;
                    <i class="fa-solid fa-caret-left"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://maxgentechnologies.com/services "
                  target="_blank"
                >
                  <span className="hover">
                    App Development &nbsp;<i class="fa-solid fa-caret-left"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://maxgentechnologies.com/services "
                  target="_blank"
                >
                  <span className="hover">
                    Logo Design & Graphic Design &nbsp;
                    <i class="fa-solid fa-caret-left"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://maxgentechnologies.com/services "
                  target="_blank"
                >
                  <span className="hover">
                    Support & Maintenance (Web & Android) &nbsp;
                    <i class="fa-solid fa-caret-left"></i>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://maxgentechnologies.com/services "
                  target="_blank"
                >
                  <span className="hover">
                    E-commerce Web Development &nbsp;
                    <i class="fa-solid fa-caret-left"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="sec4-right" data-aos="fade-left">
            <img src={expertise} alt="Our Expertise & Support" />
          </div>
        </div>
        <div>
          <div className="sec5" data-aos="fade-down">
            <h2>Grab The Opportunity to Work with us & Learn As Well</h2>
            <p>
              Maxgen's internship will allow you to work alongside and pick the
              brains of a group of web developers with the ultimate knowledge
              for Mobile app development, Android app development, Flutter app
              development, WordPress web development, Front end development, UI
              design, Software development etc.
            </p>
            <div className="sec5-con">
              <div className="sec5-right" data-aos="fade-right">
                <img src={intimage} alt="Our Internship offerings" />
              </div>
              <div className="sec5-left" data-aos="fade-left">
                <h1 className="heading">Our Internship offerings</h1>
                <ul>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Python Django
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Machine Learning
                        & Data Science
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> PHP Web
                        Development
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Java
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> React JS
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Android
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Software Testing
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Flutter
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> AWS Solution
                        Architect
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://maxgentechnologies.com/internship"
                      target="_blank"
                    >
                      <span className="dover">
                        <i class="fa-solid fa-caret-right"></i> Data Analytics
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-sec">
          <h2 className="blog-heading">Blogs</h2>
          <BlogSlider />
        </div>
      </div>
      <div class="overlay-btns">
        <button class="icon1 one" onClick={() => setModal(true)}>
          <span>
            <i class="fa-solid fa-book"></i>&nbsp;&nbsp;&nbsp;Looking for
            Internship ?
          </span>
        </button>
      </div>
      <ModalInt
        visible={modal}
        isVisible={() => setModal(false)}
        name="Software Testing Internship"
      >
        <ModalFormInt />
      </ModalInt>
    </>
  );
};

export default Home;
