import React from 'react'
import './style.css'

const Loader = () => {
  return (

    <div className='main'>
      <div className="loader1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Loader
